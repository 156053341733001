 
type LanguageCode = "en" | "en-GB" | "da" | "nb" | "sv" | "pl" | "fr" | "du" | "de" | "es" | "it" | "fi" | "pt";

// Define a new interface for localized strings
interface LocalizedText {
    "en": string; // US
    "en-GB": string; // UK GB
    "da": string;   // Danish
    "nb": string;    // Norwegian Bokmål
    "sv": string;    // Swedish
    "pl": string;    // Polish
    "fr": string;    // French
    "du": string;    // Dutch
    "de": string;    // German
    "es": string;
    "it": string;
    "fi": string;
    "pt": string;
}
interface ComponentData {
    value: string;
    label: LocalizedText;
}

interface DataComponentType {
    type: string;
    showAvatars: boolean;
    elements: ComponentData[]; // An array of ComponentData
}

const DataComponentList: DataComponentType[] = [
    {
        type: "job",
        showAvatars: true,
        elements: [
            {
                value: "active",
                label: {
                    da: "Igangværende Jobs",       // Danish
                    en: "Active Jobs",            // English
                    "en-GB": "Active Jobs",       // English (UK)
                    de: "Aktive Jobs",            // German
                    es: "Trabajos Activos",       // Spanish
                    fr: "Travaux Actifs",         // French
                    it: "Lavori Attivi",          // Italian
                    du: "Actieve Jobs",           // Dutch
                    nb: "Aktive Jobber",          // Norwegian Bokmål
                    pl: "Aktywne Prace",          // Polish
                    pt: "Trabalhos Ativos",       // Portuguese
                    fi: "Aktiiviset Työt",        // Finnish
                    sv: "Aktiva Jobb",            // Swedish
                }
            },
            {
                value: "completed",
                label: {
                    da: "Afsluttede Jobs",         // Danish
                    en: "Completed Jobs",          // English
                    "en-GB": "Completed Jobs",     // English (UK)
                    de: "Abgeschlossene Jobs",     // German
                    es: "Trabajos Completados",    // Spanish
                    fr: "Travaux Terminés",        // French
                    it: "Lavori Completati",       // Italian
                    du: "Voltooide Jobs",          // Dutch
                    nb: "Fullførte Jobber",        // Norwegian Bokmål
                    pl: "Zakończone Prace",        // Polish
                    pt: "Trabalhos Concluídos",    // Portuguese
                    fi: "Valmiit Työt",            // Finnish
                    sv: "Avslutade Jobb",          // Swedish
                }
            }
        ]
    },
    // ... Add other ComponentType objects as needed
    {
        type: "candidate",
        showAvatars: false,
        elements: [
            {
                value: "active",
                label: {
                    da: "Arbejder",       // Danish
                    en: "Working",            // English
                    "en-GB": "Working",       // English (UK)
                    "de": "Arbeiten",         // German
                    "es": "Trabajando",      // Spanish
                    "fr": "Travaillant",     // French
                    "it": "Lavorando",       // Italian
                    "du": "Werkend",         // Dutch
                    "nb": "Arbeider",        // Norwegian Bokmål
                    "pl": "Pracuje",         // Polish
                    "pt": "Trabalhando",     // Portuguese
                    "fi": "Työskentelee",    // Finnish
                    "sv": "Arbetar"          // Swedish
                }
            },
            {
                value: "completed",
                label: {
                    da: "Afsluttet",         // Danish
                    en: "Completed",          // English
                    "en-GB": "Completed",     // English (UK)
                    "de": "Abgeschlossen",   // German
                    "es": "Completado",     // Spanish
                    "fr": "Terminé",        // French
                    "it": "Completato",     // Italian
                    "du": "Voltooid",       // Dutch
                    "nb": "Fullført",       // Norwegian Bokmål
                    "pl": "Zakończony",     // Polish
                    "pt": "Concluído",      // Portuguese
                    "fi": "Valmis",         // Finnish
                    "sv": "Slutförd"        // Swedish
                }
            },
            {
                value: "proposal",
                label: {
                    da: "Tilbud",         // Danish
                    en: "Proposal",          // English
                    "en-GB": "Proposal",     // English (UK)
                    "de": "Vorschlag",      // German
                    "es": "Propuesta",     // Spanish
                    "fr": "Proposition",   // French
                    "it": "Proposta",      // Italian
                    "du": "Voorstel",      // Dutch
                    "nb": "Forslag",       // Norwegian Bokmål
                    "pl": "Propozycja",    // Polish
                    "pt": "Proposta",      // Portuguese
                    "fi": "Ehdotus",       // Finnish
                    "sv": "Förslag"        // Swedish
                }
            }
            ,
            {
                value: "interview",
                label: {
                    da: "Interview",         // Danish
                    en: "Interview",          // English
                    "en-GB": "Interview",     // English (UK)
                    "de": "Interview",      // German
                    "es": "Entrevista",    // Spanish
                    "fr": "Entretien",     // French
                    "it": "Colloquio",     // Italian
                    "du": "Interview",     // Dutch
                    "nb": "Intervju",      // Norwegian Bokmål
                    "pl": "Wywiad",        // Polish
                    "pt": "Entrevista",    // Portuguese
                    "fi": "Haastattelu",   // Finnish
                    "sv": "Intervju"       // Swedish
                }
            }
            ,
            {
                value: "notdialog",
                label: {
                    da: "Ingen Dialog",         // Danish
                    en: "No Dialog",          // English
                    "en-GB": "No Dialog",     // English (UK)
                    "de": "Kein Dialog",    // German
                    "es": "Sin Diálogo",   // Spanish
                    "fr": "Pas de Dialogue", // French
                    "it": "Nessun Dialogo",  // Italian
                    "du": "Geen Dialoog",   // Dutch
                    "nb": "Ingen Dialog",   // Norwegian Bokmål
                    "pl": "Brak Dialogu",   // Polish
                    "pt": "Sem Diálogo",    // Portuguese
                    "fi": "Ei Dialogia",    // Finnish
                    "sv": "Ingen Dialog"    // Swedish
                }
            }
            ,
            {
                value: "greylisted",
                label: {
                    da: "Negativeliste",         // Danish
                    en: "Greylisted",          // English
                    "en-GB": "Greylisted",     // English (UK)
                    "de": "Grauliste",      // German
                    "es": "Lista Gris",    // Spanish
                    "fr": "Liste Grise",   // French
                    "it": "Lista Grigia",  // Italian
                    "du": "Grijs Lijst",   // Dutch
                    "nb": "Grålistet",     // Norwegian Bokmål
                    "pl": "Szara Lista",   // Polish
                    "pt": "Lista Cinza",   // Portuguese
                    "fi": "Harmaa Lista",  // Finnish
                    "sv": "Grålistad"      // Swedish
                }
            }
        ]
    },
    // ... Add other ComponentType objects as needed
    {
        type: "matching",
        showAvatars: false,
        elements: [
            {
                value: "open",
                label: {
                    da: "Åbne",       // Danish
                    en: "Open",            // English
                    "en-GB": "Open",       // English (UK)
                    "de": "Offen",        // German
                    "es": "Abierto",      // Spanish
                    "fr": "Ouvert",       // French
                    "it": "Aperto",       // Italian
                    "du": "Open",         // Dutch
                    "nb": "Åpen",         // Norwegian Bokmål
                    "pl": "Otwarte",      // Polish
                    "pt": "Aberto",       // Portuguese
                    "fi": "Avoinna",      // Finnish
                    "sv": "Öppen"         // Swedish
                }
            },
            {
                value: "matched",
                label: {
                    da: "Matchede",         // Danish
                    en: "Matched",          // English
                    "en-GB": "Matched",     // English (UK)
                    "de": "Abgeglichen",  // German
                    "es": "Emparejado",  // Spanish
                    "fr": "Correspondant", // French
                    "it": "Corrispondente", // Italian
                    "du": "Overeenkomen",  // Dutch
                    "nb": "Matchet",      // Norwegian Bokmål
                    "pl": "Dopasowane",   // Polish
                    "pt": "Correspondido", // Portuguese
                    "fi": "Sovitettu",    // Finnish
                    "sv": "Matchad"       // Swedish
                }
            }

        ]
    },
    // ... Add other ComponentType objects as needed
    {
        type: "candidatejob",
        showAvatars: true,
        elements: [
            {
                "value": "inmatching",
                "label": {
                    "da": "Igangværende Matchning",   // Danish
                    "en": "Ongoing Matchings",        // English
                    "en-GB": "Ongoing Matchings",     // English (UK)
                    "de": "Aktives Matching",       // German
                    "es": "Emparejamiento Activo",  // Spanish
                    "fr": "Appariement Actif",      // French
                    "it": "Matching Attivo",        // Italian
                    "du": "Actief Matchen",         // Dutch (Again, consider 'nl' as the standard code for Dutch)
                    "nb": "Aktiv Samsvarende",      // Norwegian Bokmål
                    "pl": "Aktywne Dopasowywanie",  // Polish
                    "pt": "Correspondência Ativa",  // Portuguese
                    "fi": "Aktiivinen Yhdistäminen",// Finnish
                    "sv": "Aktiv Matchning"         // Swedish
                }
            },
            {
                value: "active",
                label: {
                    da: "Igangværende Jobs",       // Danish
                    en: "Active Jobs",            // English
                    "en-GB": "Active Jobs",       // English (UK)
                    de: "Aktive Jobs",            // German
                    es: "Trabajos Activos",       // Spanish
                    fr: "Travaux Actifs",         // French
                    it: "Lavori Attivi",          // Italian
                    du: "Actieve Jobs",           // Dutch
                    nb: "Aktive Jobber",          // Norwegian Bokmål
                    pl: "Aktywne Prace",          // Polish
                    pt: "Trabalhos Ativos",       // Portuguese
                    fi: "Aktiiviset Työt",        // Finnish
                    sv: "Aktiva Jobb",            // Swedish
                }
            },
        
            {
                value: "completed",
                label: {
                    da: "Afsluttede Jobs",         // Danish
                    en: "Completed Jobs",          // English
                    "en-GB": "Completed Jobs",     // English (UK)
                    de: "Abgeschlossene Jobs",     // German
                    es: "Trabajos Completados",    // Spanish
                    fr: "Travaux Terminés",        // French
                    it: "Lavori Completati",       // Italian
                    du: "Voltooide Jobs",          // Dutch
                    nb: "Fullførte Jobber",        // Norwegian Bokmål
                    pl: "Zakończone Prace",        // Polish
                    pt: "Trabalhos Concluídos",    // Portuguese
                    fi: "Valmiit Työt",            // Finnish
                    sv: "Avslutade Jobb",          // Swedish
                }
            },
            {
                value: "notselected",
                label: {
                    da: "Fravalgte job",       // Danish
                    en: "Deselected Jobs",    // English
                    "en-GB": "Deselected Jobs", // English (UK)
                    de: "Abgewählte Jobs",    // German
                    es: "Trabajos No Seleccionados",  // Spanish
                    fr: "Emplois Désélectionnés",    // French
                    it: "Lavori Deselezionati",  // Italian
                    du: "Niet-gekozen Banen",   // Dutch (I've noticed you've used 'du' for Dutch, but the standard code is 'nl'. Please adjust if necessary.)
                    nb: "Fravalgte jobber",    // Norwegian Bokmål
                    pl: "Nie wybrane oferty pracy", // Polish
                    pt: "Trabalhos Não Selecionados",   // Portuguese
                    fi: "Hylätyt Työpaikat",   // Finnish
                    sv: "Ej valda jobb"       // Swedish
                }
            },
        ]
    },
];

export { DataComponentList }