import { createResource, createSignal, createEffect, For, Show, onMount, Ref, Switch as SolidSwitch, Match } from "solid-js";
import { useNavigate } from "@solidjs/router";

import { Avatar, Dialog, Button, Stack, Select, MenuItem, Card, ListItem, ListItemButton, ListItemAvatar, ListItemText,  } from "@suid/material";
import { SelectChangeEvent } from "@suid/material/Select";
import { AddIcon } from '../icons';
import { SmallListComponentItem } from '../SmallComponents/SmallOrganizationCard'
import { getURL } from '../../../utils/fetchAPI'
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { showError } from '../SmallComponents/SmallShowError'
import { getText, GetTextUI, getCurrentUILanguageCode } from '../SmallComponents/Translate'
import { DataComponentList } from '../DataLists/DataComponentList'

const apiUrl = import.meta.env.VITE_APP_API_URL;

type ListElement = {
    id: string;
    title: string;
    subTitle1?: string;
    subTitle2?: string;
    subTitle3?: string;
    description?: string;
    highlightText?: string;
    rating?: string;
    src?: string;
    filterValue?: string;
};

type FilterElement = {
    label: string;
    value: string;
    showAvatars: boolean;
    type: "customerjob" | "organization" | "customercandidate" | "customermatching" | "candidatejob" | 'candidatematching'
};


type MainListComponentProps = {
    // dataLists: ListElement[];
    // filterLists: FilterElement[];
    type: "customerjob" | "organization" | "customercandidate" | "customermatching" | "candidatejob" | 'candidatematching'
    defaultFilterValue: string;
    openAsDialog: boolean;
    handleCloseEvent?: () => void;
    // handleMinimize: (direction: string) => void;
    // handleMaximize: (direction: string) => void;
};


const MainListComponent = (props: MainListComponentProps) => {
    const [isSmall, setIsSmall] = createSignal(false)

    const txtActive = getText("default", "active")
    let defaultFilterList: FilterElement[] = [
        { value: 'active', label: "default label", showAvatars: false, type: "customercandidate" },
    ];
    let defaultDataList: ListElement[] = [
        { id: "0", title: "Element", subTitle1: "Sub Element", subTitle2: "Sub Element 2", rating: "0" },
    ];

    const [addRouterText, setAddRouterText] = createSignal<string>("");

    // Convert lists into a local reactive state

    const [clickURL, setClickURL] = createSignal("")


    const [localLists, setLocalLists] = createSignal<ListElement[]>(defaultDataList);
    const [filterLists, setFilterLists] = createSignal<FilterElement[]>(defaultFilterList);
    const [activeFilter, setActiveFilter] = createSignal<String>(props.defaultFilterValue);
    // const [activeFilter, setActiveFilter] = createSignal<FilterElement | undefined>();

    const handleMinimize = () => {
        // setIsSmall(true)
    };
    const handleMaximize = () => {
        setIsSmall(false)
    };

    const navigate = useNavigate();
    const handleJobAdd = () => {

        navigate(addRouterText())
    }

    const handleFilterChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value;
        setActiveFilter(newValue)
    };

    // create a loading state signal
    const [isLoading, setIsLoading] = createSignal(true);

    const updateFilterList = (type: string) => {
        let idxCountryCode = "" as string;
        // createEffect(() => {
        idxCountryCode = getCurrentUILanguageCode();
        //  })

        // Map over the filtered items to construct the desired FilterElement structure
        const newFilterList: FilterElement[] = DataComponentList.filter(option => option.type === type).flatMap(component =>
            component.elements.map(element => ({
                value: element.value,
                label: (element.label[idxCountryCode as keyof typeof element.label] as string),
                showAvatars: component.showAvatars,
                type: component.type as "customerjob" | "customercandidate" | "customermatching" | "candidatejob" | 'candidatematching'
            }))
        ).reduce<FilterElement[]>((acc, curr) => acc.concat(curr), []);

        setFilterLists(newFilterList)

        // set active filter
        if (newFilterList.length > 0) {
            setActiveFilter(newFilterList[0].value);
        }

    }

    async function getCustomerJobs() {
/*
        type Job = {
            JobId: string;
            JobName: string;
            JobDescription: string;
            JobWFStatus: string;
        }*/
        type job = {
           
            id: string;
            title: string;
            subTitle1: string;
            subTitle2: string;
            subTitle3: string;
            highlightText: string;
            rating: string;
            src: string;
            filterValue: string;
        }[] | null;
        type JobFetchType = {
            status: string;
            redirect: string;
            jobs: candidate;
        };
        setClickURL('/customerjob')
        setAddRouterText('/customerjobadd')
        updateFilterList("job")
        const txtRoles = getText("default", "roles")

        const txtError = getText("default", "error", [{ name: "durring", value: " reading of jobs" }])
        await getURL(`${apiUrl}/job/all`, 'GET', null)
            //  await getURL(`${apiUrl}/customerjob`, 'GET', null)
            .then((fetchedData: JobFetchType) => {
                // transform data
                if (!Array.isArray(fetchedData?.jobs)) {
                    throw new Error("Invalid data format: jobs is not an array");
                }
                const transformedData = fetchedData?.jobs?.map(job => ({
                    id: job.id,
                    title: job.title,
                    highlightText: job.highlightText,
                    subTitle1: job.subTitle1,
                
                    subTitle2: job.subTitle2 ? `${job.subTitle2} ${txtRoles() as string}` : "",
                 
                    filterValue: job.filterValue,
                }));

                // update the lists state
                setLocalLists([...localLists(), ...transformedData]);

                //  toast.success('Finish loading', { id: toastId, });
            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
            .finally(() => {
                setIsLoading(false) // Set loading state to false at the end of the request
            }
            );
    }

    type candidate = {
        /*
        candidateId: string;
        candidateFirstName: string;
        candidateLastName: string;
        candidateDescription: string;
        candidateWFStatus: string;
        candidateCountry: string;
        candidateCity: string;
        src: string;
        filterValue: string;
        */
        id: string;
        title: string;
        subTitle1: string;
        subTitle2: string;
        subTitle3: string;
        highlightText: string;
        rating: string;
        src: string;
        filterValue: string;
    }[] | null;
    type CandidatesFetchType = {
        status: string;
        redirect: string;
        candidates: candidate;
    };

    async function getCustomerCandidates() {

        setClickURL('/candidates')
        updateFilterList("candidate")

        const txtError = getText("default", "error")
        const txtRoles = getText("default", "roles")

        await getURL(`${apiUrl}/candidate/all`, 'GET', null)
            //  await getURL(`${apiUrl}/customerjob`, 'GET', null)
            .then((fetchedData: CandidatesFetchType) => {
                // Check if candidates is an array and not null
                if (!Array.isArray(fetchedData?.candidates)) {
                    throw new Error("Invalid data format: candidates is not an array");
                }

                // transform data
                const transformedData = fetchedData?.candidates?.map(candidate => ({
                    id: candidate.id,
                    title: candidate.title,
                    highlightText: candidate.highlightText,
                    subTitle1: candidate.subTitle1,
                    subTitle2: candidate.subTitle2,
                    subTitle3: candidate.subTitle3 ? `${candidate.subTitle3} ${txtRoles() as string}` : "",
                    rating: candidate.rating,
                    src: candidate.src,
                    filterValue: "active"
                }));

                // update the lists state
                setLocalLists([...localLists(), ...transformedData]);

                //  toast.success('Finish loading', { id: toastId, });
            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
            .finally(() => {
                setIsLoading(false) // Set loading state to false at the end of the request
            }
            );

    }


    async function getCustomerMatching() {

        type Candidate = {
            CandidateId: string;
            CandidateName: string;
            CandidateDescription: string;
            CandidateWFStatus: string;
            CandidateCountry: string;
            CandidateCity: string;
        }
        setClickURL('/customermatching')
        updateFilterList("matching")


        const txtError = getText("default", "error")
        //  await getURL(`${apiUrl}/candidate/mycv`, 'GET', null)
        await getURL(`${apiUrl}/candidate/all`, 'GET', null)
            .then((fetchedData: Candidate[]) => {
                // transform data
                const transformedData = fetchedData.map(candidate => ({
                    id: candidate.CandidateId,
                    title: candidate.CandidateName,
                    highlightText: candidate.CandidateWFStatus,
                    subTitle1: (candidate.CandidateDescription.length > 50 ? candidate.CandidateDescription.slice(0, 50) + '...' : candidate.CandidateDescription),
                    subTitle2: `${candidate.CandidateCountry} ${candidate.CandidateCity}`,
                    subTitle3: "Number of stars:",
                    src: getDummyAvatar(),
                    filterValue: "open"
                }));
                //  console.log("fetchedData: " + fetchedData)
                //  console.table("transformedData: " + transformedData)
                // update the lists state
                setLocalLists([...localLists(), ...transformedData]);

            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
            .finally(() => {
                setIsLoading(false) // Set loading state to false at the end of the request
            }
            );
    }


    async function getCandidateJobs() {

        type Job = {
            JobId: string;
            JobName: string;
            JobDescription: string;
            JobWFStatus: string;
        }
        setClickURL('/candidatejob')
        updateFilterList("candidatejob")

        const txtError = getText("default", "error", [{ name: "durring", value: " reading of jobs" }])
        await getURL(`${apiUrl}/job`, 'GET', null)
            // await getURL(`${apiUrl}/candidatejob`, 'GET', null)
            .then((fetchedData: Job[]) => {
                // transform data
                const transformedData = fetchedData.map(job => ({
                    id: job.JobId,
                    title: job.JobName,
                    highlightText: job.JobWFStatus,
                    subTitle1: job.JobDescription,
                    filterValue: "active"
                }));

                // update the lists state
                setLocalLists([...localLists(), ...transformedData]);

                //  toast.success('Finish loading', { id: toastId, });
            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
            .finally(() => {
                setIsLoading(false) // Set loading state to false at the end of the request
            }
            );
    }

    onMount(async () => {
        setIsLoading(true);
        //  const toastId = toast.loading('Loading...');

        if (props.type === 'customerjob')
            getCustomerJobs()
        else if (props.type === 'customercandidate')
            getCustomerCandidates()
        else if (props.type === 'customermatching' || props.type === 'candidatematching')
            getCustomerMatching()
        else if (props.type === 'candidatejob')
            getCandidateJobs()

    });

    let cardRef: HTMLDivElement | undefined;
    const [cardWidth, setCardWidth] = createSignal(0);

    // Once the component is mounted
    createEffect(() => {
        if (cardRef) {
            const ro = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    // Update the width
                    setCardWidth(entry.contentRect.width);
                }
            });

            // Observe the card
            ro.observe(cardRef);

            // Cleanup the observer when component is unmounted
            return () => ro.disconnect();
        }
    });

    const getStringToLeftOfPipe = (input: string): string => {
        if (!input) {
            // Return empty string if input is empty or undefined
            return "";
        }
    
        const parts = input.split('|');
        if (parts.length === 1) {
            // Return full string if no '|' character is found
            return input;
        }
    
        // Return the string to the left of the '|' character
        return parts[0];
    }

    const ListContents = () => {

        const navigate = useNavigate();
        return (
            <>
                {/*  Ensure that the outermost Stack takes up the full screen height. */}
                <Stack margin={2} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    {/*      <Card sx={{ px: 1, py: 1, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>  */}
                    <Stack spacing={1} direction="column">
                        <Show
                            when={props.type === "customerjob"}
                        >
                            <Button fullWidth variant="contained" size="small" onClick={handleJobAdd}>
                                <AddIcon />
                                
                                <GetTextUI formName="jobpage" label="addjob"/>
                            </Button>

                        </Show>
                        {/*   disabled={isLoading()}// Disable the select while data is loading */}
                        {/* cardWidth() */}
                        <Card sx={{ height: '100%' }}>
                            <Select
                                id="addAnotherExternalUserId"
                                value={activeFilter() as string}
                                defaultValue={activeFilter() as string}
                                fullWidth={true}
                                size="small"
                                variant="outlined"

                                onChange={handleFilterChange}
                            >
                                {filterLists().map((option, index) => (
                                    <MenuItem
                                        value={option.value as string}
                                    >
                                        <ListItemText>

                                            {`${option.label} (${localLists().filter(item => item.filterValue === option.value).length})`}

                                        </ListItemText>
                                    </MenuItem>
                                ))}

                            </Select>

                        </Card>
                    </Stack>
                    <br />

                    <SolidSwitch>
                        <Match when={cardWidth() < 250}>
                            <For each={localLists().filter(item => item.filterValue === activeFilter())}>
                                {(item) =>
                                    <ListItem disablePadding  >
                                        <ListItemButton onClick={() => navigate(`${clickURL()}/${item.id}`)}>
                                            <Show when={props.type === "customercandidate"}>
                                                <ListItemAvatar >
                                                    <Avatar variant="rounded" src={item.src} />
                                                </ListItemAvatar>
                                            </Show>
                                            <ListItemText primary={item.title} secondary={getStringToLeftOfPipe(item.subTitle2 as string)} />
                                                 
                                          
                                        </ListItemButton>
                                    </ListItem>
                                }
                            </For>
                        </Match>
                        <Match when={cardWidth() < 320}>
                            <For each={localLists().filter(item => item.filterValue === activeFilter())}>
                                {(item) => <SmallListComponentItem withAvatar={props.type === "customerjob" ? false : true} clickURL={`${clickURL()}/${item.id}`} src={item.src} title={item.title} subTitle1={item.subTitle1} subTitle2={getStringToLeftOfPipe(item.subTitle2 as string)} subTitle3={item.subTitle3} highlightText={item.highlightText} rating={""} description={""} />}
                            </For>
                        </Match>
                        <Match when={cardWidth() >= 320}>
                            <For each={localLists().filter(item => item.filterValue === activeFilter())}>
                                {(item) => <SmallListComponentItem withAvatar={props.type === "customerjob" ? false : true} clickURL={`${clickURL()}/${item.id}`} src={item.src} title={item.title} subTitle1={item.subTitle1} subTitle2={item.subTitle2} subTitle3={item.subTitle3} highlightText={item.highlightText} rating={item.rating} description={item.description} />}
                            </For>
                        </Match>
                    </SolidSwitch>

                    {/*   </Card>   */}
                </Stack>
            </>
        )

    }

    const [openDialog, setOpenDialog] = createSignal(props.openAsDialog);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
        props.handleCloseEvent && props.handleCloseEvent();
    }
    return (
        <>

            {/* Only present a button if a very small screen. Else show the list */}
            <div ref={cardRef}>

                <Show
                    when={openDialog()}
                    fallback={
                        <ListContents />
                    }
                >
                    <Dialog open={openDialog()} onBackdropClick={handleCloseDialog} >
                        <ListContents />
                    </Dialog>
                </Show>
            </div>
        </>
    );
}
export { MainListComponent } 